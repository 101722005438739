const Footer = () => {
  const handlePhoneClick = (phoneNumber) => {
    const telLink = `tel:${phoneNumber}`;
    window.location.href = telLink;
  };

  const handleEmailClick = () => {
    const email = "info@seatravel.by";
    const mailtoLink = `mailto:${email}`;
    window.open(mailtoLink, "_blank");
  };
  return (
    <footer className="footer">
      <ul className="footer__wrapperInfo">
        <li className="info">
          <h4 className="info__title">Реквизиты</h4>
          <p className="info__text">
            ОДО “Ситревел” <br /> УНП 191454264 <br /> Адрес: 220012, Республика
            Беларусь, <br />
            г. Минск, ул. Гикало, д.3 <br /> Свидетельство о государственной{" "}
            <br /> регистрации № 191454264 от 07.12.2010 <br /> выдано Минским
            горисполкомом <br /> Режим работы 10:00-19:00 <br /> Телефон:{" "}
            <span
              className="click"
              onClick={() => handlePhoneClick("+375296217161")}
            >
              +375 29 621 71 61
            </span>{" "}
            <br /> e-mail:{" "}
            <span className="click" onClick={handleEmailClick}>
              info@seatravel.by
            </span>
          </p>
        </li>
        <li className="info">
          <h4 className="info__title">ул. Гикало, 3</h4>
          <p className="info__text">Телефон:</p>
          <p
            className="info__text click"
            onClick={() => handlePhoneClick("+375293102200")}
          >
            +375 29 310 22 00
          </p>
          <p
            className="info__text click"
            onClick={() => handlePhoneClick("+375333102202")}
          >
            +375 33 310 22 02
          </p>
        </li>
        <li className="info">
          <h4 className="info__title">ул. Притыцкого 27А</h4>
          <p className="info__text">Телефон:</p>
          <p
            className="info__text click"
            onClick={() => handlePhoneClick("+375293082200")}
          >
            +375 29 308 22 00
          </p>
          <p
            className="info__text click"
            onClick={() => handlePhoneClick("+375333102203")}
          >
            +375 33 310 22 03
          </p>
        </li>
      </ul>
      <ul className="footer__wrapperText">
        <li className="text">
          все цены опубликованные на сайте носят информационный характер и не
          являются ни публичной офертой, определяемой положениями Статьи 407
          Гражданского кодекса Республики Беларусь, ни рекламой. Цены в валюте
          указаны справочно и являются эквивалентом цене в белорусских рублях.
          Оплата происходит в белорусских рублях по курсу туроператора.
          Актуальную стоимость и наличие мест вы можете уточнить у наших
          менеджеров.
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
